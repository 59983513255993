const Map = () => {
    return (
        <iframe
            title="this is title"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d22659.77320709435!2d17.219921!3d44.771189!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475e02fe049f214f%3A0x533072e23a2106ce!2sBanja%20Luka%2078000!5e0!3m2!1sen!2sba!4v1703459921194!5m2!1sen!2sba"
        ></iframe>
    );
};

export default Map;
